import React from 'react';
import navigationStyles from '../styles/Navigation.module.css'
import elementStyles from '../styles/Elements.module.css'
import streamExLogo from '../assets/streamXLogo.svg'

import { useAuth } from '../context/AuthenticationModal'

function Navigation({ phase }: { phase: number }) {
    const { authenticateUser, displayContactUs, togglePhase } = useAuth();

    return (
        <div className='row justify-content-center'>
            <div className={navigationStyles.nav}>

                <div className={navigationStyles.logoDisplay}>
                    <img src={streamExLogo} alt="streamex logo" />
                </div>

                <div>
                    {phase === 1 &&
                        <button className={elementStyles.buttonSecondary} onClick={togglePhase}>Phase 2</button>
                    }
                    {phase === 2 &&
                        <>
                        {/*<button className={elementStyles.buttonSecondary} onClick={displayContactUs}>Learn More</button>*/}
                        <button className={elementStyles.buttonSecondary} onClick={togglePhase}>Phase 1</button>
                        </> 
                    }
                    {/*<button className={elementStyles.buttonPrimary} onClick={authenticateUser}>Sign In</button>*/}

                    <button className={elementStyles.buttonPrimary} onClick={authenticateUser}>Sign up</button>
                    
                </div>
            </div>
        </div>
    );
}

export default Navigation;
