import React from 'react';

import Navigation from '../components/Navigation';
import Banner from '../components/Banner';
import OurPartners from '../components/OurPartners';
import StreamEcosystem from '../components/StreamEcosystem';
import WhyStreamEx from '../components/WhyStreamex';
import Media from '../components/Media';
import TheTeam from '../components/TheTeam';
import News from '../components/News';
import ContactUs from '../components/ContactUs';
import StreamexBig from '../components/StreamExBig';

import { useAuth } from '../context/AuthenticationModal'

function LandingPage() {
    const { getPhase } = useAuth()

    const phase2 = () => {
        return (
            <>
                <Navigation
                    phase={2}
                />
                <div className='row justify-content-center' style={{
                    paddingTop: "81px" // For Nav
                }}>
                    <div className='bodyInfo'>
                        <Banner 
                            phase={2}
                        />
                        <OurPartners
                            phase={2}
                        />
                        <StreamEcosystem
                            phase={2}
                        />
                        <WhyStreamEx
                            phase={2}
                        />
                        <Media />
                        <TheTeam />
                        <News />
                        <ContactUs />
                    </div>
                </div>
                <StreamexBig />
            </>
        )
    }
    const phase1 = () => {
        return (
            <>
                <Navigation
                    phase={1}
                />
                <div className='row justify-content-center' style={{
                    paddingTop: "81px" // For Nav
                }}>
                    <div className='bodyInfo'>
                        <Banner 
                            phase={1}
                        />
                        <OurPartners
                            phase={1}
                        />
                        <StreamEcosystem
                            phase={1}
                        />
                        <WhyStreamEx
                            phase={1}
                        />
                        <Media />
                        <TheTeam />
                        <News />
                        <ContactUs />
                    </div>
                </div>
                <StreamexBig />
            </>
        )
    }

    return (
        <>
            {getPhase() === 1 ? phase1() : phase2()}
        </>
    )

}

export default LandingPage;