import React from 'react';
import StreamExBig from '../assets/STREAMEX.png'

function StreamexBig() {
    return (
        <div className='d-block' style={{
            marginTop: "57px"
        }}>
            <img src={StreamExBig}
                alt="StreamEx in large gray letters"
                style={{
                    width: "100%",
                    left: 0
                }}
            />
        </div>
    );
}

export default StreamexBig;
