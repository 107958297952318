import React from 'react';
import elementStyles from '../styles/Elements.module.css'

import CoinBaseLogo from '../assets/partners/Coinbase.svg'
import DeepGreenLogo from '../assets/partners/DeepGreen.svg'
import Osisko from '../assets/partners/Osisko.svg'
import ElectricElementsMining from '../assets/partners/ElectricElementsMining.svg'
import DuMoulinBlack from '../assets/partners/DumoulinBlack.svg'
import Fasken from '../assets/partners/Fasken.svg'


function partnerBlock(logo: string) {

    return (
        <div className='col-3 col-md-2 pl-3 pb-5'>
            <div className={elementStyles.block + ' transparentBg p-0'}>
                <img style={{width: "100%"}} src={logo} alt={logo} />
            </div>
        </div>
    )
}

function OurPartners({ phase }: { phase: number }) {

    const getPartnersP2 = () => {
        return (
            <>
                <div className='d-none d-md-block'>
                    <div className='row gx-3 justify-content-center'>
                        {partnerBlock(Osisko)}
                        {partnerBlock(DeepGreenLogo)}
                        {/*partnerBlock(CoinBaseLogo)*/}
                        {partnerBlock(Fasken)}
                        {partnerBlock(ElectricElementsMining)}
                        {partnerBlock(DuMoulinBlack)}
                    </div>
                </div>
                <div className='d-block d-md-none'>
                    <div className='row gx-3 justify-content-center'>
                        {partnerBlock(Osisko)}
                        {partnerBlock(DeepGreenLogo)}
                        {/*partnerBlock(CoinBaseLogo)*/}
                    </div>
                    <div className='row gx-3 justify-content-center'>
                        {partnerBlock(Fasken)}
                        {partnerBlock(ElectricElementsMining)}
                        {partnerBlock(DuMoulinBlack)}
                    </div>
                </div>
            </>
        )
    }

    const getPartnersP1 = () => {
        return (
            <>
                <div className='d-none d-md-block'>
                    <div className='row gx-3 justify-content-center'>
                        {partnerBlock(DeepGreenLogo)}
                        {/*partnerBlock(CoinBaseLogo)*/}
                        {partnerBlock(Fasken)}
                        {partnerBlock(ElectricElementsMining)}
                        {partnerBlock(DuMoulinBlack)}
                    </div>
                </div>
                <div className='d-block d-md-none'>
                    <div className='row gx-3 justify-content-center'>
                        {partnerBlock(DeepGreenLogo)}
                        {/*partnerBlock(CoinBaseLogo)*/}
                        {partnerBlock(Fasken)}
                    </div>
                    <div className='row gx-3 justify-content-center'>
                        
                        {partnerBlock(ElectricElementsMining)}
                        {partnerBlock(DuMoulinBlack)}
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className="container text-center" style={{
            marginTop: "100px"
        }}>
            <p className='secondaryColor headingPadding'>Our Partners</p>

            {phase === 1 ? getPartnersP1() : getPartnersP2()}
        </div>
    );
}

export default OurPartners;
