import React from 'react';
import elementStyles from '../styles/Elements.module.css'

import accessImage from '../assets/whyStreamex/access.png'
import diversificationImage from '../assets/whyStreamex/diversification.png'
import legalImage from '../assets/whyStreamex/legal.png'
import secureImage from '../assets/whyStreamex/secure.png'
import freedomImage from '../assets/whyStreamex/freedom.png'

import accessImageP1 from '../assets/whyStreamex/accessP1.png'
import diversificationImageP1 from '../assets/whyStreamex/diversificationP1.png'
import innovation from '../assets/whyStreamex/innovation.png'
import legalImageP1 from '../assets/whyStreamex/legalP1.png'
import secureImageP1 from '../assets/whyStreamex/secureP1.png'

import imageContainerBorder from '../assets/ImageContainerBorder.svg'

/*
<img
    className={elementStyles.blockImage}
    style={{position: "absolute", top: "0", zIndex: 1}}
    src={imageContainerBorder} alt="A list of StreamEx Exchange tokens" 
/>
*/
function blocklayout(
    index: string, logo: string,
    title: string, description: string) {

    return (
        <div className='row d-flex' style={{
            margin: 0, padding: "10px"
        }}>

            <h1 className={elementStyles.listIndicator + ' col-2 primaryColor titleFont'} style={{
                textAlign: "start"
            }}>{index}</h1>
            <div className={'col-10 ' + elementStyles.block + ' ' + elementStyles.blockShadow} style={{
                padding: "10px"
            }}>
                <div className='row w-100'>
                    <div className='col-12 col-md-7 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                        <div style={{height: "fit-content"}}>
                            <p className='SubHeaderText' style={{textAlign: "start"}}>{title}</p>
                            <p>{description}</p>
                        </div>
                    </div>


                    <div className={'col-12 d-md-none ' + elementStyles.blockImageContainer}
                        style={{
                            backgroundImage: `url(${imageContainerBorder})`,
                            backgroundSize: "100%",
                            paddingTop: "20px",
                        }}
                    >
                        <img
                            className={elementStyles.blockImage}
                            style={{width: "100%"}}
                            src={logo} alt="A list of StreamEx Exchange tokens" 
                        />
                        
                    </div>

                    <div className={'d-none col-md-5 d-md-block ' + elementStyles.blockImageContainer}
                        style={{
                            backgroundImage: `url(${imageContainerBorder})`,
                            backgroundSize: "100%",
                            paddingTop: "20px"
                        }}
                    >
                        <img
                            className={elementStyles.blockImage}
                            style={{width: "100%", maxHeight: "200px", objectFit: "contain", zIndex: 2}}
                            src={logo} alt="A list of StreamEx Exchange tokens" 
                        />
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

function WhyStreamEx({ phase }: { phase: number }) {
    return (
        <div className="row text-center justify-content-center" style={{
            marginTop: "180px"
        }}>
            <p className='headerText headingPadding titleFont'>Why use Streamex?</p>
            
            {phase === 1 &&
                <div className='text-start'>
                    {blocklayout("1", accessImageP1, "Access", "Access to 500+ Million new investors and 3 Trillion dollars of new liquidity.")}
                    {blocklayout("2", diversificationImageP1, "Diversification", "Diversified access to a range of private placements in the traditional capital markets.")}
                    {blocklayout("3", innovation, "Innovation", "Create and own individualized royalties and streaming contracts through tokenization.")}
                    {blocklayout("4", legalImageP1, "Legal", "A comprehensive legal pathway walking together with regulators to create a safe, stable and regulated environment for modern investors.")}
                    {blocklayout("5", secureImageP1, "Secure", "A streamlined legal framework for financing documentation using automation and blockchain technology.")}
                </div>
            }
            {phase === 2 &&
                <div className='text-start'>
                    {blocklayout("1", accessImage, "Access", "Access to Royalties, Streams and other commodity markets currently inaccessible to retail participants")}
                    {blocklayout("2", diversificationImage, "Diversification", "Directly own a variety of Real World Assets and trade them on chain with enhanced liquidity")}
                    {blocklayout("3", legalImage, "Legal", "Working to be fully regulated across multiple domains, the Middle East, Africa, Canada and the USA")}
                    {blocklayout("4", secureImage, "Secure", "Streamlined automated onboarding and due-diligence for companies to tokenize and list their assets")}
                    {blocklayout("5", freedomImage, "Freedom", "Create and manage your own portfolio of RWA’s without the need for any third party intervention")}
                </div>
            }
        </div>
    );
}

export default WhyStreamEx;
