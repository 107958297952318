import React from 'react';
import './App.css';

import LandingPage from './components/Landing';

import { AuthContextProvider } from './context/AuthenticationModal'

function App() {


    return (
        <div>
            <AuthContextProvider>
                <LandingPage />
            </AuthContextProvider>
        </div>
    );
}

export default App;
