import React, {createContext, useContext, useState} from 'react'
import modalStyles from '../styles/ModalStyles.module.css'
import elementStyles from '../styles/Elements.module.css'
import axios from 'axios';

const AuthContext = createContext<any>({})
export const useAuth = () => useContext(AuthContext)

export const AuthContextProvider = ({children}: {children: React.ReactNode}) => {
    const [showAuth, setShowAuth] = useState<boolean>(false);
    const [showContact, setShowContact] = useState<boolean>(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
    const [successFor, setSuccessFor] = useState<string>('');

    const [inputName, setInputName] = useState<string>('');
    const [inputEmail, setInputEmail] = useState<string>('');
    const [inputCountry, setInputCountry] = useState<string>('');
    const [inputCompany, setInputCompany] = useState<string>('');
    const [inputMessage, setInputMessage] = useState<string>('');

    const [currentPhase, setCurrentPhase] = useState<number>(1);
    
    const authenticateUser = () => {
        setShowAuth(true)
    }

    const displayContactUs = () => {
        setShowContact(true)
    }

    const togglePhase = () => {
        if (currentPhase === 1) {
            setCurrentPhase(2);
        } else {
            setCurrentPhase(1);
        }
    }

    const getPhase = () => {
        return currentPhase;
    }

    const userFormSubmission = async() => {

        if (showContact === false && inputEmail === "") {
            alert("email can't to be blank")
            return;
        }

        else if (showContact === true && (inputName === "" || inputEmail === "" || inputCompany === "" || inputMessage === "")) {
            alert("No fields can be blank")
            return;
        }

        try {
            if (showContact === true) {                
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const data = JSON.stringify({
                    "Name": inputName,
                    "Email": inputEmail,
                    "Company": inputCompany,
                    "Message": inputMessage
                });

                const requestOptions: RequestInit = {
                    method: 'POST',
                    body: data
                };
                  
                try {
                    const response = await fetch("https://az4udvltp6m5yyxvbnyuqqmyq40ohliw.lambda-url.us-east-1.on.aws/", requestOptions);
                    const result = await response.json();
                } catch (error) {
                    console.log('error', error);
                }
            } else { 
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const data = JSON.stringify({
                    "Name": inputName,
                    "Email": inputEmail,
                    "Country": inputCountry,
                    "Message": inputMessage
                });

                const requestOptions: RequestInit = {
                    method: 'POST',
                    body: data
                };
                  
                try {
                    const response = await fetch("https://uglp4ncwmk2pads374bg7wkyay0ebzfa.lambda-url.us-east-1.on.aws/", requestOptions);
                    const result = await response.json();
                } catch (error) {
                    console.log('error', error);
                }
            }
          } catch (error) {
            console.error('Error submitting form:', error);
            return;
          }

        activateSuccessBanner(true, showContact === true ? 'contact' : 'auth');
        resetView(null, true);
    }

    const activateSuccessBanner = (open = true, bannerFor = "contact") => {
        if (open === false) {
            setShowSuccessDialog(false);
            setSuccessFor('');
        } else {
            setShowSuccessDialog(true);
            setSuccessFor(bannerFor);
            // Remove the dialog after 5 seconds
            setTimeout(() => {
                setShowSuccessDialog(false);
                setSuccessFor('');
            }, 5000)
        }
    }

    const resetView = (e: any, force = false) => {
        if (force === true || e.id === "background") {
            setShowAuth(false);
            setShowContact(false);

            setInputName('');
            setInputEmail('');
            setInputCountry('');
            setInputCompany('');
            setInputMessage('');
        }
    }

    const authDisplayOLD = () => {
        if (showAuth === false && showContact === false) {
            return (<></>)
        }

        return (
            <div id="background" className={modalStyles.modalContainer} onClick={(e)=> {
                resetView(e.target)
            }}>
                <div id="modal" className={modalStyles.modal} onClick={()=>{}}>
                    <h3>{showContact ? "Contact Us" : "Sign In"}</h3>
                    <p className={modalStyles.elementPadding}>Welcome to Streamex.</p>

                    <h4>Email</h4>
                    <input 
                        className={modalStyles.elementPadding}
                        value={inputEmail}
                        onChange={(e) => {
                            setInputEmail(e.currentTarget.value)
                        }}
                        type="email" id="email" name="email"
                        placeholder='Info@streamex.com'
                    />

                    <h4>Password</h4>
                    <input 
                        className={modalStyles.elementPadding}
                        
                        type="password" id="password" name="password"
                        placeholder='********'
                    />

                    <button 
                        className={elementStyles.buttonPrimary} 
                        onClick={userFormSubmission}
                    >{showContact ? "Send" : "Log in"}</button>
                </div>
            </div>
        )
    }

    const authDisplay = () => {
        if (showAuth === false && showContact === false) {
            return (<></>)
        }

        return (
            <div id="background" className={modalStyles.modalContainer} onClick={(e)=> {
                resetView(e.target)
            }}>
                <div id="modal" className={modalStyles.modal} onClick={()=>{}}>
                    <h3>{showContact ? "Contact Us" : "Sign Up"}</h3>
                    <p className={modalStyles.elementPadding}>Enjoy Beta Access and our Newsletter.</p>
                
                    <h4>Name</h4>
                    <input 
                        className={modalStyles.elementPadding} 
                        value={inputName}
                        onChange={(e) => {
                            setInputName(e.currentTarget.value)
                        }}
                        type="text" id="name" name="name"
                        placeholder='Enter Name...'
                    />

                    <h4>Email</h4>
                    <input 
                        className={modalStyles.elementPadding}
                        value={inputEmail}
                        onChange={(e) => {
                            setInputEmail(e.currentTarget.value)
                        }}
                        type="email" id="email" name="email"
                        placeholder='Info@streamex.com'
                    />

                    {showContact === true &&
                        <>
                            <h4>Company</h4>
                            <input 
                                className={modalStyles.elementPadding}
                                value={inputCompany}
                                onChange={(e) => {
                                    setInputCompany(e.currentTarget.value)
                                }}
                                type="text" id="company" name="company"
                                placeholder='Enter Company...'
                            />
                        </>
                    }

                    {showContact === false &&
                        <>
                            <h4>Country of Residence</h4>
                            <input 
                                className={modalStyles.elementPadding}
                                value={inputCountry}
                                onChange={(e) => {
                                    setInputCountry(e.currentTarget.value)
                                }}
                                type="text" id="country" name="country"
                                placeholder='Enter Country...'
                            />
                        </>
                    }

                    <h4>Leave a Message</h4>
                    <textarea 
                        className={modalStyles.elementPadding}
                        value={inputMessage}
                        onChange={(e) => {
                            setInputMessage(e.currentTarget.value)
                        }}
                        id="message" name="message"
                        rows={5}
                        
                        placeholder='Anything you want to say?'
                    />

                    <button 
                        className={elementStyles.buttonPrimary} 
                        onClick={userFormSubmission}
                    >{showContact ? "Send" : "Sign Up"}</button>
                </div>
            </div>
        )
    }

    const successDialogDisplay = () => {
        if (showSuccessDialog === false) {
            return (<></>)
        }
        
        return (
            <div className={modalStyles.successDialog}>
                <div>
                    {successFor === 'contact' &&
                        <>
                            <h4>Thank you for reaching out!</h4>
                            <p>We will get back to you shortly</p>
                        </>
                    }

                    {successFor === 'auth' &&
                        <>
                            <h4>Thank you for signing up!</h4>
                            <p>Your registration was received.</p>
                        </>
                    }
                    
                </div>
                
                <i className="bi bi-x" onClick={() => { activateSuccessBanner(false) }}></i>

            </div>
        )
    }

    return (
        <AuthContext.Provider value={{
            authenticateUser,
            displayContactUs,
            togglePhase,
            getPhase
        }}>
            {successDialogDisplay()}
            {authDisplay()}
            {children}
        </AuthContext.Provider>
    );
}
