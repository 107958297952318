import React from 'react';
import bannerRightImage from '../assets/bannerRightImage.png'
import bannerRightImagePhase1 from '../assets/bannerRightImagePhase1.png'
import bannerStyles from '../styles/Banner.module.css'
import elementStyles from '../styles/Elements.module.css'

import { useAuth } from '../context/AuthenticationModal'

function Banner({ phase }: { phase: number }) {
    const { authenticateUser } = useAuth();

    const TitleText = () => {

        if (phase === 1) {
            return (
                <>
                    <p className={bannerStyles.titleTextPhase1}>A Tokenization <br />Platform for <br /><span className='primaryColor'>Commodity Royalties</span></p>
                    <p className={bannerStyles.description + ' secondaryColor'}>Streamex is a financing tool bridging the traditional capital markets with modern capital.</p>
                </>
            )
        } else if (phase === 2) {
            return (
                <>
                    <p className={bannerStyles.titleText}>The Modern <br /><span className='primaryColor'>Exchange</span></p>
                    <p className={bannerStyles.description + ' secondaryColor'}>Streamex is the full service platform to buy, sell, and own a wide range of commodity and Real World Assets assets on-chain.</p>
                </>
            )
        }
    }

    return (
        <div className={'row g-1 ' + bannerStyles.banner} style={{
            marginTop: "40px"
        }}>
            <div className={'col-12 col-md-6 mb-3'}>
                <div className={elementStyles.block}>
                    <div className='d-block'>
                        {TitleText()}
                        <button className={elementStyles.buttonPrimary} onClick={authenticateUser}>Sign Up</button>
                    </div>
                </div>
            </div>

            <div className={'col-12 col-md-6 mb-3'}>
                <div className={elementStyles.block + ' primaryBg pl-0 pr-0'}>
                    <img className={bannerStyles.largeImage} src={phase === 1 ? bannerRightImagePhase1 : bannerRightImage} alt='Panel of the product' />
                </div>
            </div>
        </div>
    );
}

export default Banner;
