import React from 'react';
import elementStyles from '../styles/Elements.module.css'
import { useAuth } from '../context/AuthenticationModal'

function ContactUs() {
    const { displayContactUs } = useAuth();

    return (
        <div className="row text-center justify-content-center" style={{
            marginTop: "220px"
        }}>
            <div className='col-12 mb-3'>
                <div className={elementStyles.block + ' primaryBg p-3 d-block'}>
                    <h1 className='text-white contactUsText titleFont'>Contact Us</h1>
                    <p className='text-white contactUsDescription'>For more information on Streamex and investment information please contact us! </p>

                    <button className={elementStyles.buttonBlack} onClick={displayContactUs}>Contact Us</button>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
