import React from 'react';
import elementStyles from '../styles/Elements.module.css'

import MikeStephensImage from '../assets/team/mike_stephens.png'
import HentyMcphieImage from '../assets/team/henry_mcphie.png'
import MorganLekstronImage from '../assets/team/morgan_lekstrom.png'
import DeepGreenAILogo from '../assets/team/deepgreenAILogo.png'

function teamCard(
    firstName: string, lastName: string, 
    description: string, imageUrl: string
) {
    return (
        <div className='col-6 col-lg-3' style={{
            marginBottom: "24px"
        }}>
            
            <div className={elementStyles.teamImageContainer}>
                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top"
                    }}
                src={imageUrl} alt={imageUrl} />
            </div>

            <p className='SubHeaderText2 m-0 mt-3 titleFont' style={{textAlign: "start"}}><span className='primaryColor'>{firstName}</span> {lastName}</p>
            <p className='BodyText secondaryColor m-0'>{description}</p>
        </div>
    )
}

function TheTeam() {
    return (
        <div className={elementStyles.block + ' p-3 d-block'} style={{
            height: "fit-content",
            marginTop: "180px"
        }}>
            <p className='col-12 headerText headingPadding titleFont'>The Team</p>
            
            <div className='row'>
                {teamCard("Morgan", "Lekstrom", "Co-Founder", MorganLekstronImage)}
                {teamCard("Henry", "McPhie", "Co-Founder", HentyMcphieImage)}
                {teamCard("Mike", "M. Stephens", "Chief Legal Partner", MikeStephensImage)}
                {teamCard("DeepGreen", "AI", "Development Partner", DeepGreenAILogo)}
            </div>
        </div>
    );
}

export default TheTeam;
