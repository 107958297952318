import React from 'react';
import elementStyles from '../styles/Elements.module.css'

import ComingSoonVideo from '../assets/ComingSoonVideo.png'

function Media() {
    return (
        <div className="row text-center justify-content-center" style={{
            marginTop: "180px"
        }}>
            <p className='col-12 headerText headingPadding titleFont'>Media</p>
            
            <div className='col-12 col-md-8 mb-3'>
                <div className={elementStyles.block + ' p-3'}>
                    <img
                        style={{
                            width: "100%",
                            cursor: "pointer"
                        }}
                    
                    src={ComingSoonVideo} alt='Panel of the product' />
                </div>
            </div>
        </div>
    );
}

export default Media;
