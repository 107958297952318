import React from 'react';
import elementStyles from '../styles/Elements.module.css'

import StreamTokensLargeGraphic from '../assets/StreamexExchangeTokens.png'
import MultiTokenEcosystem from '../assets/MultiTokenEcosystem.png'
import StexOfficialTokenGraphic from '../assets/StexOfficialTokenGraphic.png'

function StreamEcosystem({ phase }: { phase: number }) {
    
    const phase2 = () => {
        return (
            <>
                <div className='col-12' style={{margin: 0, padding: "10px"}}>
                    <div className={elementStyles.block + ' ' + elementStyles.blockShadow}>
                        <div className='row w-100'>
                            <div className='col-12 col-md-7 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                                <div style={{height: "fit-content"}}>
                                    <p className='SubHeaderText titleFont' style={{textAlign: "start"}}>The <span className='primaryColor'>Exchange</span></p>
                                    <p>The Streamex Exchange is an all in one exchange for the trade as well as generation of tokenized Real World Assets.</p>
                                </div>
                            </div>


                            <div className='col-12 d-md-none'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%"}}
                                    src={StreamTokensLargeGraphic} alt="A list of StreamEx Exchange tokens" 
                                />
                            </div>

                            <div className='d-none col-md-5 d-md-block'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%", maxHeight: "300px", objectFit: "contain"}}
                                    src={StreamTokensLargeGraphic} alt="A list of StreamEx Exchange tokens" 
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>


                <div className='col-12 col-md-6' style={{margin: 0, padding: "10px"}}>
                    <div className={elementStyles.block + ' ' + elementStyles.blockShadow}>
                        <div className='row w-100'>
                            <div className='col-12 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                                <div style={{height: "fit-content"}}>
                                    <p className='SubHeaderText titleFont' style={{textAlign: "start"}}>The <span className='primaryColor'>MultiToken</span> Ecosystem</p>
                                    <p>The Streamex MultiToken Ecosystem is the environment in which all tokenized assets live and are generated for trade within Streamex.</p>
                                </div>
                            </div>

                            <div className='col-12 d-md-block'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%", maxHeight: "300px", objectFit: "contain"}}
                                    src={MultiTokenEcosystem} alt="A multi token ecosystem" 
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>


                <div className='col-12 col-md-6' style={{margin: 0, padding: "10px"}}>
                    <div className={elementStyles.block + ' ' + elementStyles.blockShadow}>
                        <div className='row w-100'>
                            <div className='col-12 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                                <div style={{height: "fit-content"}}>
                                    <p className='SubHeaderText titleFont' style={{textAlign: "start"}}><span className='primaryColor'>$STEX</span> Official Exchange Token</p>
                                    <p>$STEX is the official exchange token for Streamex offering utilities as well as acting as an algorithmically balanced ETF of all assets on the platform.</p>
                                </div>
                            </div>

                            <div className='col-12 d-md-block'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%", maxHeight: "300px", objectFit: "contain"}}
                                    src={StexOfficialTokenGraphic} alt="A list of StreamEx Exchange tokens" 
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const phase1 = () => {
        return (
            <>
                <div className='col-12' style={{margin: 0, padding: "10px"}}>
                    <div className={elementStyles.block + ' ' + elementStyles.blockShadow}>
                        <div className='row w-100'>
                            <div className='col-12 col-md-7 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                                <div style={{height: "fit-content"}}>
                                    <p className='SubHeaderText titleFont' style={{textAlign: "start"}}><span className='primaryColor'>Private</span> Placement Tool</p>
                                    <p>Streamex is a platform-level financing tool modernizing the traditional capital markets with blockchain-based capital. Opening up the investor base to 500+ million new modern investors.</p>
                                </div>
                            </div>


                            <div className='col-12 d-md-none'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%"}}
                                    src={StreamTokensLargeGraphic} alt="A list of StreamEx Exchange tokens" 
                                />
                            </div>

                            <div className='d-none col-md-5 d-md-block'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%", maxHeight: "300px", objectFit: "contain"}}
                                    src={StreamTokensLargeGraphic} alt="A list of StreamEx Exchange tokens" 
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>


                <div className='col-12 col-md-6' style={{margin: 0, padding: "10px"}}>
                    <div className={elementStyles.block + ' ' + elementStyles.blockShadow}>
                        <div className='row w-100'>
                            <div className='col-12 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                                <div style={{height: "fit-content"}}>
                                    <p className='SubHeaderText titleFont' style={{textAlign: "start"}}>The <span className='primaryColor'>MultiToken</span> Ecosystem and Integrated Asset Exchange</p>
                                    <p>Streamex is building an ecosystem bridging traditional capital markets with modern capital while seamlessly building the first integrated global asset exchange for both tokenized assets and stock.</p>
                                </div>
                            </div>

                            <div className='col-12 d-md-block'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%", maxHeight: "300px", objectFit: "contain"}}
                                    src={MultiTokenEcosystem} alt="A multi token ecosystem" 
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>


                <div className='col-12 col-md-6' style={{margin: 0, padding: "10px"}}>
                    <div className={elementStyles.block + ' ' + elementStyles.blockShadow}>
                        <div className='row w-100'>
                            <div className='col-12 d-flex' style={{textAlign: "start", alignItems: "center"}}>
                                <div style={{height: "fit-content"}}>
                                    <p className='SubHeaderText titleFont' style={{textAlign: "start"}}>Tokenized <span className='primaryColor'>Royalty</span> Contract<br /><br /></p>
                                    <p>Streamex Tokenized Royalty Contracts allow for the creation and sale of individualized royalties creating access to a new asset class for everyone<br /></p>
                                </div>
                            </div>

                            <div className='col-12 d-md-block'>
                                <img
                                    className={elementStyles.blockImage}
                                    style={{width: "100%", maxHeight: "300px", objectFit: "contain"}}
                                    src={StexOfficialTokenGraphic} alt="A list of StreamEx Exchange tokens" 
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="row text-center justify-content-center" style={{
            marginTop: "180px"
        }}>
            <p className='headerText headingPadding titleFont'>The Streamex Ecosystem</p>

            {phase === 1 ? phase1() : phase2()}
            
        </div>
    );
}

export default StreamEcosystem;
