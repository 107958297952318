import React from 'react';
import elementStyles from '../styles/Elements.module.css'

import EmptyImageLogo from '../assets/EmptyImageLogo.png'


function newsCard(title: string, description: string) {
    return (
        <div className='col-12 col-lg-4 mb-2'>
            <div className={elementStyles.block + ' d-block p-3'}>
                <img
                    style={{
                        width: "100%",
                        minHeight: "200px",
                        maxHeight: "320px",
                    }}
                src={EmptyImageLogo} alt='Panel of the product' />

                <p className='SubHeaderText2 m-0 mt-3 titleFont' style={{textAlign: "start"}}>{title}</p>
                <p className='BodyText secondaryColor m-0'>{description}</p>
            </div>
        </div>
    )
}

function News() {
    return (
        <div className='p-3 d-block' style={{
            marginTop: "180px"
        }}>
            <p className='col-12 headerText headingPadding mb-4'>News</p>
            
            <div className='row g-3 justify-content-between mt-3'>
                {newsCard(
                    "StreamEx News Flow", 
                    "Press releases and relevant articles for shareholders and the public will appear here shortly. Stay Tuned!"
                )}
                {newsCard(
                    "StreamEx News Flow", 
                    "Press releases and relevant articles for shareholders and the public will appear here shortly. Stay Tuned!"
                )}
                {newsCard(
                    "StreamEx News Flow", 
                    "Press releases and relevant articles for shareholders and the public will appear here shortly. Stay Tuned!"
                )}
            </div>
        </div>
    );
}

export default News;
